<template>
  <div class="placeOder_content">
    <div class="vancard-header" @click="goAddress()">
      <div class="vancard-first">
        <span v-if="addresslist.receiver_name" style="margin-right: 20px">
          {{ addresslist.receiver_name }}
        </span>
        <span v-if="addresslist.name" style="margin-right: 20px">
          {{ addresslist.name }}
        </span>
        <span>{{ addresslist.tel }}</span>
      </div>

      <div class="vancard-second">、
        <div v-if="addresslist.isDefault === 1" class="vancard-is_default">
          默认
        </div>
        <div class="vancard-address">
          {{ addresslist.province_name }}{{ addresslist.city_name }}{{ addresslist.address }}
        </div>
      </div>
      <div class="vancard-icon">
        <van-icon name="arrow" />
      </div>
    </div>

    <!-- 商品卡片 -->
    <div class="group_xxon">
      <div class="group_box">
        <div class="group_top">
          <div class="group_image">
            <img :src="goodsList.goodsImg" alt>
          </div>
          <div class="group_detail">
            <div class="group_detail_title">
              {{ goodsList.goodsName }}
            </div>
            <div class="group_detail_txt">
              {{ goodsList.skuText }}
            </div>
            <div class="group_detail_txt">¥{{ goodsList.goodsPrice }}</div>
            <div class="group_detail_lay">¥{{ goodsList.goodsVipPrice }}</div>
            <div class="group_detail_buynum">
              <!-- <van-stepper v-model="items.goodNum" /> -->
              x{{ goodsList.goodsNum }}
            </div>
          </div>
        </div>
        <div v-if="chengzhangzhi" class="group_bottom">
          <span>赠品</span>
          <span>成长值*{{ chengzhangzhi }}</span>
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="group_total">
        共{{ goodsList.goodsNum }}件,小计
        <span style="color: red; font-size: 18px; font-weight: 600">¥{{ blanceInfolist.totalPrice }}</span>元
      </div>
    </div>
    <!-- 菜单选项 -->
    <van-cell-group style="text-align: left; margin: 10px auto">
      <van-cell
        title="商品金额"
        :value="'¥' + blanceInfolist.goodsTotalprice"
      />
      <van-cell title="运费" :value="'¥' + blanceInfolist.goodsFreight" />
      <!-- <van-cell title="悦积分" is-link value="无可用" /> -->
      <!-- 优惠券单元格 -->
      <!-- <van-coupon-cell
                :coupons="couponList"
                :chosen-coupon="chosenCoupon"
                @click="showList = true"
            /> -->

      <van-cell v-if="integral && integral.isHavePoint === 0" is-link>
        <template #title> 悦积分</template>

        <template>
          <span class="custom-no-value"> {{ integral.pointNum }} </span>
        </template>
      </van-cell>

      <van-cell
        v-if="integral && integral.isHavePoint === 1"
        is-link
        @click="showPointChange"
      >
        <template #title>
          悦积分
          <!-- <span class="custom-title">{{ integral.pointNum }}</span> -->
        </template>

        <template>
          <span
            :class="selectPoint.isSelect ? 'custom-value' : 'custom-no-value'"
          >
            {{ selectPoint.isSelect ? selectPoint.selectInfo.text : '去选择' }}
          </span>
        </template>
      </van-cell>

    </van-cell-group>
    <!-- <van-cell-group style="text-align:left;">
      <van-cell title="商品金额" value="¥89.00" />
      <van-cell title="运费" value="¥0.00" />
      <van-cell title="优惠券" value="-¥40.00" />
    </van-cell-group>-->
    <!-- 优惠券列表 -->

    <!-- 优惠券列表 -->
    <van-popup
      v-model="showList"
      round
      position="bottom"
      style="height: 90%; padding-top: 4px"
    >
      <van-coupon-list
        :coupons="couponList"
        :chosen-coupon="chosenCoupon"
        :show-exchange-bar="false"
        @change="onChange"
      />
    </van-popup>
    <!-- 支付方式 -->
    <div class="pay_boxtype">
      <p class="pay_title">支付方式</p>
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell title="微信支付">
            <template #right-icon>
              <van-radio checked-color="#07c160" name="1" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <van-submit-bar
      v-if="isSale === 1"
      :price="banCprice"
      :loading="isDisabledSubmitBtn"
      button-text="提交订单"
      @submit="onSubmit"
    />
    <van-submit-bar
      v-if="isSale === 0"
      disabled
      :price="banCprice"
      :loading="isDisabledSubmitBtn"
      button-text="已售罄"
    />

    <!-- 积分规则 -->
    <van-popup
      v-model="showPoint"
      class="PointPop"
      round
      position="bottom"
      :style="{ height: '40%' }"
    >
      <div class="PointBox">
        <div class="PointTop">
          <div class="PointTopTitle">悦积分</div>
          <div class="closePop" @click="showPoint = false">
            <img src="../../assets/images/closesku.png">
          </div>
        </div>

        <div v-if="PointDetail" class="PointContentBox">
          <div
            v-if="PointDetail && PointDetail.deductionPoint === 0"
            class="PointRadio"
          >
            {{ PointDetail.text }}
          </div>

          <div
            v-if="PointDetail && PointDetail.deductionPoint !== 0"
            class="PointRadio"
            @click="showRadioChange"
          >
            <div>
              {{ PointDetail.text }}
            </div>
            <div class="deductionBtn">

              <span v-if="showRadio"> <img src="../../assets/images/deductionBtn.png"></span>
              <span v-else> <img src="../../assets/images/deductionBtnNo.png"></span>

            </div>
          </div>

          <div class="PointContentInfo">
            悦积分账户数：{{ PointDetail.availableBean }} 账户悦积分：{{ PointDetail.availableScore }}
            <span @click="showPop = true">使用规则 <img src="../../assets/images/PointContentInfo.png"></span>
          </div>

        </div>

        <div
          v-if="PointDetail && PointDetail.deductionPoint === 0"
          class="noPointFooter"
        >
          使用
        </div>
        <div v-else class="PointFooter" @click="setPointChange">使用</div>
      </div>
    </van-popup>

    <van-popup v-model="showPop" class="showPop">
      <div class="showPopBox">
        <div class="showPopTitle">使用规则</div>
        <div
          v-if="PointDetail"
          class="showPopCentont"
          v-html="PointDetail.content"
        />
        <div class="showPopFooter" @click="showPop = false">我知道了</div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import Vue from 'vue'
import {
  Cell,
  CellGroup,
  CouponCell,
  CouponList,
  Icon,
  Popup,
  Radio,
  RadioGroup,
  Stepper,
  SubmitBar,
  Tag,
  Toast
} from 'vant'

Vue.use(SubmitBar).use(Toast).use(CellGroup).use(Cell).use(Tag)
  .use(Icon).use(Stepper).use(Radio).use(RadioGroup).use(CouponCell)
  .use(CouponList).use(Popup)

import {
  flashBalanceNew,
  createFlashOrderNew,
  // newMallPayV4,
  // getPayVersion,
  myPoint, getProuctByPoint
} from '@/services/userApi'
import { skuChangeInfo } from '@/services/goods'
import { weChatPay } from '@/utils/payment'

export default {
  data() {
    return {
      selectPoint: {
        isSelect: false,
        selectInfo: null

      },
      showPop: false,
      showRadio: false,
      PointDetail: null,
      showPoint: false,
      integral: null,
      isSale: 0, // 已售罄还是可购买
      chengzhangzhi: 0,
      radio: '1',
      chosenContactId: 0,
      value: 1,
      chosenCoupon: -1,
      showList: false,
      couponList: [], // 优惠券列表
      disabledCoupons: [], // 不可用优惠卷
      Cid: '', // 优惠券id
      // 订单数据
      productId: '',
      productSkuId: '',
      codeNumber: '',
      room_id: '',
      live_people_id: '',
      live_id: '',
      productNum: '',
      addressIds: '',
      activityId: '',
      Uid: '',
      openId: '',
      // 下单数据
      blanceInfolist: {
        goodsTotalprice: 0,
        goodsFreight: 0
      }, // 下单集合
      addresslist: '', // 收货地址
      goodsList: '', // 商品列表
      isDisabledSubmitBtn: false,
      ordersn: '',
      banCprice: 0,
      initPrice: 0, // 初始价格
      wwwwshow: false,

      CShopId: ''
    }
  },
  computed: {
    cardType() {
      return this.chosenContactId !== null ? 'edit' : 'add'
    },
    currentContact() {
      const id = this.chosenContactId
      return id !== null ? this.list.filter((item) => item.id === id)[0] : {}
    }
  },
  created() {
    const _self = this
    const { CShopId } = this.$route.query
    _self.chengzhangzhi = this.$route.query.chengzhangzhi
    _self.productId = this.$route.query.product_id
    _self.productSkuId = this.$route.query.product_sku_id
    _self.room_id = this.$route.query.room_id
    _self.robot_send = this.$route.query.robot_send
    _self.codeNumber = this.$route.query.codeNumber
    _self.live_people_id = this.$route.query.live_people_id
    _self.live_id = this.$route.query.live_id ? this.$route.query.live_id : ''
    _self.addressIds = this.$route.query.addressIds
    _self.areaId = this.$route.query.areaId
    _self.productNum = this.$route.query.productNum
    _self.activityId = this.$route.query.activityId
    _self.Uid = localStorage.getItem('uid')
    _self.openId = localStorage.getItem('openid')
    _self.CShopId = CShopId || ''
  },
  mounted() {
    this.getProuctBlanceInfo()
  },
  methods: {
    // 是否选择积分规则
    showRadioChange() {
      this.showRadio = !this.showRadio
    },
    // 点击使用积分
    setPointChange() {
      const Price = this.initPrice
      if (this.showRadio) {
        this.selectPoint.selectInfo = this.PointDetail
        this.selectPoint.isSelect = this.showRadio
        this.selectPoint.selectInfo.text = this.PointDetail.text
        this.banCprice = Price - this.PointDetail.totalDeduction * 100
      } else {
        this.banCprice = Price
        this.selectPoint.isSelect = this.showRadio
      }
      this.showPoint = false

      // this.showRadio
    },
    // 获取积分规则详情
    showPointChange() {
      const data = {
        mid: this.Uid,
        totalPrice: this.initPrice / 100,
        goodsList: [
          {
            goodId: this.productId,
            goodSkuId: this.productSkuId,
            activityId: this.$route.query.activityId || '1'
          }
        ]
      }

      getProuctByPoint(data).then((res) => {
        if (Number(res.code) === 200) {
          res.data.content = res.data.content.replace(/\n/g, '<br>')
          this.PointDetail = res.data
          this.showRadio = this.selectPoint.isSelect
          this.showPoint = true
        }
      })
    },
    // 这里是悦积分的新规则接口
    myPoint() {
      const data = {
        mid: this.Uid,
        totalPrice: this.banCprice / 100
      }
      myPoint(data).then((resd) => {
        if (resd.code === 200) {
          this.integral = resd.data
        }
      })
    },
    // 获取商品详情 调用这个借口的主要目的就是防止用户切换地址 之后 商品不更新 可购买和已售罄的状态
    getGoodsDetail(addressId) {
      this.$store.commit('changeShowLoading', 1)

      const shareId = localStorage.getItem('shareCodeNumber')
      const data = {
        shareId,
        uid: window.localStorage.getItem('uid') || 0,
        productSkuId: this.$route.query.product_sku_id,
        productType: 0,
        liveId: this.$route.query.liveid || 0,
        addressCode: addressId,
        lon: 0,
        lat: 0
      }
      skuChangeInfo(data).then((res) => {
        if (Number(res.code) === 200) {
          this.isSale = res.data.isSale.saleState
        }
      })
    },
    // 普通商品下单
    getProuctBlanceInfo() {
      const _self = this
      const params = {
        goodsId: _self.productId,
        skuId: _self.productSkuId,
        room_id: _self.room_id,
        robot_send: _self.robot_send,
        uid: _self.Uid,
        goodsNum: _self.productNum,
        activityId: _self.activityId,
        areaId: _self.addressIds,

        CShopId: this.CShopId
      }

      if (window.localStorage.getItem('addressItem')) {
        const ads_data = JSON.parse(window.localStorage.getItem('addressItem'))
        _self.addresslist = ads_data
        _self.areaId = ads_data.areaIds
        _self.addressIds = ads_data.id
      }

      this.getGoodsDetail(_self.areaId)
      flashBalanceNew(params).then((res) => {
        if (Number(res.code) === 200) {
          if (window.localStorage.getItem('addressItem')) {
            const ads_data = JSON.parse(
              window.localStorage.getItem('addressItem')
            )
            _self.addresslist = ads_data
          } else {
            _self.addresslist = res.data.areaInfo
          }
          _self.goodsList = res.data.productInfo
          _self.blanceInfolist = res.data
          _self.banCprice = (res.data.totalPrice + res.data.goodsFreight) * 100
          _self.initPrice = (res.data.totalPrice + res.data.goodsFreight) * 100
          _self.couponList = []
          res.data.coupon &&
          res.data.coupon.forEach((ele, index) => {
            _self.couponList.push({
              available: index,
              condition: '无使用门槛\n最多优惠' + ele.amount + '元',
              reason: '',
              value: ele.amount * 100,
              name: ele.couponName,
              startAt: new Date() / 1000,
              endAt:
                new Date(ele.endtime.replace(/-/g, '/')).getTime() / 1000,
              valueDesc: ele.amount,
              unitDesc: '元',
              Cid: ele.couponId
            })
          })
          this.myPoint()
        } else {
          Toast(res.msg)
        }
      })
    },
    // 普通商品下单
    createOrderByProduct() {
      const _self = this
      let _orderFrom = 1
      if (_self.live_id !== '') {
        _orderFrom = 12
      }
      const params = {
        goodsId: _self.productId,
        uid: _self.Uid,
        skuId: _self.productSkuId,
        room_id: _self.room_id,
        robot_send: _self.robot_send,
        codeNumber: _self.codeNumber ? self.codeNumber : '',
        live_people_id: _self.live_people_id ? _self.live_people_id : '',
        live_id: _self.live_id ? _self.live_id : '',
        goodsNum: _self.productNum,
        activityId: _self.activityId,
        areaId: _self.addressIds,
        cid: _self.Cid,
        version: 2,
        type: 1,
        orderFrom: _orderFrom,
        isHadBalance: 1,
        position_from: 0,
        deductionScore: 0,
        deductionBean: 0,
        totalDeduction: 0,
        CShopId: this.CShopId
      }

      if (this.selectPoint.isSelect) {
        params.deductionScore = this.selectPoint.selectInfo.deductionScore
        params.deductionBean = this.selectPoint.selectInfo.deductionBean
        params.totalDeduction = this.selectPoint.selectInfo.totalDeduction
      }

      createFlashOrderNew(params).then((res) => {
        if (Number(res.code) === 200) {
          this.$router.push({
            path: '/pay',
            query: {
              orderNo: res.data.ordersn,
              goodsId: this.productId
            }
          })
          // return

          //   let _data = {
          //     ordersn: res.ordersn,
          //     type: 147,
          //     openId: _self.openId,
          //     returnUrl: "https://web.yuetao.vip/odersky?odersn=" + res.ordersn,
          //   };
          //   newMallPayV4(_data).then((res) => {
          //     if (Number(res.code) === 200) {
          //       _self.ordersn = res.ordersn;
          //       let params = res.pay.getwayUrl;
          //       window.location.replace(params);
          //     } else {
          //       Toast(res.msg);
          //     }
          //   });

          // const ordersn = res.data.ordersn
          // getPayVersion({ type: 1 }).then((res) => {
          //   if (Number(res.code) === 200) {
          //     const version = res.data && res.data.status
          //     const _data = {
          //       ordersn: ordersn,
          //       type: 147,
          //       openId: _self.openId,
          //       payVersion: version,
          //       returnUrl: 'https://web.yuetao.vip/odersky?odersn=' + ordersn
          //     }
          //     newMallPayV4(_data).then((res) => {
          //       if (Number(res.code) === 200) {
          //         _self.ordersn = res.ordersn
          //         // let params = res.pay.getwayUrl;
          //         // window.location.replace(params);
          //         const params = res.pay.getwayBody
          //         _self.weixinPay(params)
          //       } else {
          //         Toast(res.msg)
          //       }
          //     })
          //   }
          // })
        } else {
          Toast(res.msg)
        }
      })
    },
    // 解决微信内置对象报错
    weixinPay(params) {
      var that = this

      weChatPay(params).then(res => {
        // 支付成功后的操作
        Toast('支付成功')
        that.isDisabledSubmitBtn = true
        that.$router.push({
          path: '/oderSky',
          query: { ordersn: that.ordersn }
        })
      }).catch(e => {
        if (e) {
          const { msg = null } = e
          if (msg === 'isNotWeChat') {
            Toast('请在微信浏览器下进行支付')
          }
          if (msg === 'cancel') {
            // 取消支付的操作
            Toast('取消支付')
            that.$router.push({
              path: '/oderSky',
              query: { ordersn: that.ordersn }
            })
          }
          if (msg === 'fail') {
            // 支付失败的操作
            Toast('支付失败')
            that.$router.push({
              path: '/oderSky',
              query: { ordersn: that.ordersn }
            })
          }
        }
        that.isDisabledSubmitBtn = false // 按钮恢复高亮
      })
    },
    // 提交订单
    onSubmit() {
      const _self = this
      _self.isDisabledSubmitBtn = true
      _self.createOrderByProduct()
    },

    onChange(index) {
      this.showList = false
      this.chosenCoupon = index
      const couponInfo = this.couponList[index]
      this.banCprice = couponInfo
        ? this.initPrice - couponInfo.value
        : this.initPrice
      this.Cid = couponInfo ? couponInfo.Cid : ''
    },
    // 选地址
    goAddress() {
      this.$router.push({
        path:
          '/addressList?id=' + this.addresslist.addressId ||
          this.addresslist.address_id
      })
    }
  }
}
</script>

<style lang="less" scoped>
.placeOder_content {
  width: 100%;
  height: auto;
  overflow: auto;
  background: #f6f6f6;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .vancard-header {
    width: 100%;
    overflow: auto;
    position: relative;

    background: #fffef3;
    padding-top: 5px;

    .vancard-first {
      color: #000;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      margin-left: 15px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .vancard-second {
      text-align: left;
      margin-left: 15px;
      margin-bottom: 15px;
      width: 75%;
      line-height: 20px;
      overflow: hidden;

      .vancard-is_default {
        float: left;
        font-size: 12px;
        border: 1px solid red;
        color: red;
        padding: 1px 3px;
        vertical-align: middle;
        margin-right: 20px;
        margin-top: 5px;
      }

      .vancard-address {
        float: left;
        width: 80%;
      }
    }

    .vancard-icon {
      position: absolute;
      right: 20px;
      top: 30px;
      font-size: 18px;
    }
  }

  .vancard-header::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 0.05333rem;
    background: -webkit-repeating-linear-gradient(135deg,
    #ff6c6c 0,
    #ff6c6c 20%,
    transparent 0,
    transparent 25%,
    #1989fa 0,
    #1989fa 45%,
    transparent 0,
    transparent 50%);
    background: repeating-linear-gradient(-45deg,
    #ff6c6c 0,
    #ff6c6c 20%,
    transparent 0,
    transparent 25%,
    #1989fa 0,
    #1989fa 45%,
    transparent 0,
    transparent 50%);
    background-size: 2.13333rem;
    content: "";
  }

  .group_xxon {
    width: 100%;
    height: auto;
    background: #fff;
    padding: 10px 0;

    .group_xxon_title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 15px;
      padding-top: 10px;

      .img {
        width: 20px;
        height: 20px;
      }

      .name {
        font-size: 18px;
        font-weight: 600;
        color: #000;
        margin-left: 10px;
      }
    }

    .group_box {
      width: 100%;
      height: auto;
      overflow: auto;
      background: #fff;

      .group_top {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 135px;
        overflow: auto;

        .group_image {
          width: 100px;
          height: 100px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .group_detail {
          width: 225px;
          text-align: left;
          font-size: 12px;
          padding-left: 15px;
          position: relative;
          line-height: 20px;
          overflow: auto;

          .group_detail_title {
            color: #333333;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 13px;
            padding: 0 5px;
          }

          .group_detail_txt {
            color: #999999;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .group_detail_lay {
            color: #ff001b;
            font-size: 13px;
            font-weight: 600;
            // margin-top: 30px;
          }

          .group_detail_buynum {
            position: absolute;
            bottom: 5px;
            right: 0;
          }
        }
      }

      .group_bottom {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 90%;
        margin: 10px auto;
        background: #e6e6e6;
        font-size: 13px;
        color: #999999;
        padding: 5px 10px;
        border-radius: 5px;
      }
    }

    .group_total {
      text-align: right;
      padding-right: 25px;
      font-size: 12px;
      color: #000;
      line-height: 30px;
    }
  }

  .pay_boxtype {
    text-align: left;
    background: #fff;
    border-bottom: 100px solid #f6f6f6;
    margin-top: 20px;

    .pay_title {
      line-height: 40px;
      padding-left: 15px;
      font-size: 15px;
      font-weight: 600;
    }
  }
}

//强改
/deep/ .van-nav-bar {
  .van-icon {
    color: #000;
  }
}

/deep/ .van-cell__value {
  color: red;
}

.van-cell-group {
  .van-cell__title {
    font-size: 10px;
  }

  .van-cell__value {
    font-size: 10px;
  }
}

.custom-title {
  color: #969799;
}

.custom-no-value {
  color: #969799;
}

.custom-value {
  color: red;
}

/deep/ .PointPop {
  /deep/ .van-popup {
    background: #fff !important;
  }
}

.PointBox {
  background: #fff;
  position: relative;
  height: 100%;

  .PointTop {
    padding: 10px 0;
    height: 50px;
    position: relative;

    .PointTopTitle {
      line-height: 30px;
      font-weight: bold;
    }
  }

  .PointContentBox {
    padding: 0 20px;
    text-align: left;
  }

  .PointFooter {
    position: absolute;
    width: 90%;
    margin: 0 auto;
    height: 40px;
    background: #f7263c;
    color: #fff;
    line-height: 40px;
    border-radius: 10px;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 20px;
  }

  .noPointFooter {
    position: absolute;
    width: 90%;
    margin: 0 auto;
    height: 40px;
    background: #ccc;
    color: #fff;
    line-height: 40px;
    border-radius: 10px;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 20px;
  }
}

.PointRadio {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  font-weight: bold;
  position: relative;

  .deductionBtn {
    position: absolute;
    right: 0;
    top: 10px;

    img {
      width: 15px;
      height: 15px;
    }
  }
}

.PointContentInfo {
  padding: 10px 0;
  font-weight: 400;
  color: #666666;

  span {
    color: #f7263c;
    margin-left: 15px;

    img {
      width: 15px;
      height: 15px;
      vertical-align: -2px;
    }
  }
}

.closePop {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 15px;

  img {
    width: 15px;
    height: 15px;
  }
}

.showPop {
  width: 90%;
  height: 240px;
  border-radius: 10px;
  padding: 10px;

  .showPopBox {
    width: 100%;
    height: 100%;
    position: relative;

    .showPopTitle {
      font-weight: bold;
    }

    .showPopCentont {
      padding: 10px 10px;
      text-align: left;
      line-height: 24px;
      font-size: 13px;
      font-weight: 400;
    }

    .showPopFooter {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 90%;
      height: 40px;
      background: #f7263c;
      color: #fff;
      border-radius: 10px;
      line-height: 40px;
    }
  }
}
</style>
